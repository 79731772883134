.product_tile {
    color: black;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: white;
    outline: none;
    box-shadow: none;
    border: 0;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.main_div {
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #B2B2B2;
    width: 90%;
    height: 100%;
}

.prod_info {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 0;
    align-items: center;
}

.prod_image_div {
    width: auto;
    height: 50px;
}

.prod_image {
    height: 50px;
    width: 50px;
}

.prod_details {
    margin: 0 2%;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 14px;
}

.prod_name {
    max-width: 30ch;
    text-align: left;
    height: 100%;
    padding: 0 0 4px;
    line-height: 16px;
    font-size: 15px;
}

.prod_name p {
    font-family: PoppinsMedium;
    margin: 0;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: normal;
    overflow: hidden;
    padding-bottom: 1px;
}

.prod_tagline {
    color: #1b1b1b;
    font-size: 10px;
    text-align: left;
    line-height: 12px;
}

.prod_tagline p {
    font-family: PoppinsLight;
    margin: 0;
}

.prod_price_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 20%;
}

.prod_price_div .prod_price {
    font-size: 14px;
    font-weight: bold;
    text-align: right;
}