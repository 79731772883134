.prod_detail .carousel {
    background-color: white;
    width: 100%;
    height: auto;
    margin: 10px auto 20px;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 10px -8px #e7e7e7;
}

.prod_detail .carousel .carousel-indicators {
    order: 2;
}

.prod_detail .carousel ol.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin: 0 15%;
}

.prod_detail .carousel .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    margin: 0 3px;
    background: url('../../../images/Dots/five.svg') no-repeat;
    text-indent: -999px;
}

.prod_detail .carousel .carousel-indicators li.active {
    background: url('../../../images/Dots/one.svg') no-repeat;
}

.prod_detail .carousel .carousel-inner {
    width: 60%;
    margin: 0 auto;
}

.prod_detail .carousel .carousel-item {
    display: none;
}

.prod_detail .carousel .carousel-item.active {
    display: block;
}

.prod_detail .carousel .carousel-item img {
    width: 100%;
}

.prod_detail .carousel [class*='carousel-control'] {
    display: none;
}

.prod_detail .image_tile {
    width: 50%;
    height: auto;
    max-width: 200px;
    max-height: 200px;
    padding: 10px;
}

.prod_detail .prod_info {
    display: flex;
    flex-direction: row;
    width: unset;
    padding: 0;
    margin: 2% 5% 0;
}

.prod_detail .prod_details {
    margin: 0;
    width: 70%;
}

.prod_detail .prod_name {
    margin: 0 0 5px;
    font-size: 16px;
}

.prod_detail .prod_tagline {
    font-size: 12px;
}

.prod_detail .prod_price_div {
    width: 30%;
    align-items: unset;
}

.prod_detail .prod_price {
    text-align: right;
    font-size: 18px;
    font-weight: bold;
}

.buy_btn {
    width: 100%;
    height: 48px;
    position: fixed;
    bottom: 0;
    background-color: white;
}

.buy_btn button {
    height: 48px;
    font-size: 16px;
}

.prod_desc {
    font-size: 11px;
    color: #1b1b1b;
    text-align: left;
    margin: 0 5% 120px;
    line-height: 18px;
}

.prod_desc p {
    font-family: PoppinsLight;
}
