@font-face {
    font-family: Circular-pro;
    src: url(assets/fonts/lineto-circular-pro-book.ttf) format('truetype');
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: PoppinsMedium;
    src: url(assets/fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
    font-family: PoppinsLight;
    src: url(assets/fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: PoppinsSemiBold;
    src: url(assets/fonts/Poppins-SemiBold.ttf) format('truetype');
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
}

body, * {
  font-family: Poppins, Circular-pro, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
    font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
}

:focus {
    outline: unset;
}

a {
    text-decoration: none;
}

.client_logo {
    width: 33%;
}

.client_logo img {
    width: 100%;
}

button.modal_btn {
    background-color: white;
    outline: none;
    box-shadow: none;
    border: 0;
    color: #3CBDE2;
    margin: 0;
    font-size: 14px;
    font-style: italic;
    text-transform: none;
}

.ReactModal__Overlay {
    opacity: 1 !important;
    overflow: scroll;
    background-color: rgba(255, 255, 255, 1) !important;
}

.ReactModal__Content {
    position: absolute;
    background: #ffffff;
    outline: none;
    padding: 20px;
    height: 100%;
}

.modalHeader {
    display: flex;
    float: right;
    position: fixed;
    right: 20px;
}

.modalHeader button {
    padding: 0;
}

.modalHeader button svg {
    fill: #3CBDE2;
    width: 30px;
    height: 30px;
}

.needHelp .instructions {
    align-items: flex-start;
    text-align: left;
}

.instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.instructions p {
    font-size: 16px;
    text-transform: none;
    font-weight: unset;
}

.instructions p.title {
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    font-weight: bold;
}

.instructions p.question {
    color: #3CBDE2;
    font-size: 110%;
    font-weight: bold;
}

.instructions ol {
    margin: 0;
    padding-left: 15px;
}

.needHelp .instructions p {
    margin: 0;
}

.needHelp .instructions p.question {
    margin-top: 20px;
}

.modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    font-weight: bold;
}

.okBtn {
    background-color: #3CBDE2;
    color: white;
    height: 36px;
    width: 35%;
    align-items: center;
    border: none;
}
