header[class*='AppBar'] {
    height: 44px;
    margin-bottom: 10px;
    box-shadow: 0 10px 10px -8px #e7e7e7;
}

header[class*='AppBar'] [class*='Toolbar'] {
    min-height: unset;
    height: 100%;
    display: flex;
}

header[class*='AppBar'] .header_logo {
    width: 25%;
}

header[class*='AppBar'] .header_logo img {
    width: 50%;
    display: flex;
}

header[class*='AppBar'] .drop_div {
    align-items: center;
    justify-content: center;
    background-color: transparent;
    flex-grow: 1;
}

header[class*='AppBar'] .drop_div .dropdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

header[class*='AppBar'] .drop_div .dropdown .drop_btn {
    background-color: transparent;
    border: none;
    box-shadow: none;
    font-size: 14px;
    color: #292A2B;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
}

header[class*='AppBar'] .drop_div .dropdown .drop_btn svg {
    width: 35px;
    height: 35px;
    color: #3CBDE2;
}

header[class*='AppBar'] .drop_div .dropdown .menu_div {
    align-items: center;
    justify-content: center;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    background-color: white;
    filter: drop-shadow(0 5px 5px #eeeeee);
    border: 1px solid #eeeeee;
    padding: 10px;
    min-width: 25%;
}

header[class*='AppBar'] .drop_div .dropdown .menu_div .address {
    color: black;
    text-decoration: none;
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
}

header[class*='AppBar'] .drop_div .dropdown .menu_div .address p {
    text-align: left;
}

header[class*='AppBar'] .drop_div .dropdown .menu_div .change_store {
    background-color: #3CBDE2;
    color: white;
    height: 30px;
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

header[class*='AppBar'] .search {
    width: 25%;
}

header[class*='AppBar'] .search button {
    padding: 0;
    display: flex;
    margin: 0 0 0 auto;
}




.footer {
    padding: 35px 0;
}

.footer .logo {
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
}

.footer .logo_image {
    width: 64px;
    vertical-align: middle;
    margin-left: 5px;
}